import axios from 'axios';
import qs from 'qs';
import Cookie from '../Cookie';

export default class Popsical {
  static uri(path) {
    return process.env.REACT_APP_POPSICAL_URL + path
  }

  static headers() {
    return {
      'content-type': 'application/json',
      'accept': 'application/json',
      'authorization': `Bearer ${Cookie.get('__POPSICAL_TOKEN__')}`
    };
  }

  static public_headers() {
    return {
      'content-type': 'application/json',
      'accept': 'application/json',
      'x-popsical-signature': process.env.REACT_APP_POPSICAL_PUBLIC_PATH_KEY
    }
  }

  static async request(resources, params, opts = {}) {
    let headers = {};

    if (opts.usePublicHeaders) {
      headers = Popsical.public_headers();
    }
    else {
      headers = opts.headers ? opts.headers : Popsical.headers();
    }

    let endpoint = Popsical.uri(resources);

    let options = Object.assign(
      {},
      {
        url: endpoint,
        headers,
      },
      opts,
    );

    return axios(endpoint, options)
      .then(Popsical.handleResponse)
      .catch(e => {
        throw Popsical.handleError(e);
      });
  }

  static serialize(params) {
    return JSON.stringify(params);
  }

  static post(resources, params, raw = false, opts) {
    opts = Object.assign({}, opts, {
      method: 'POST',
      data: raw ? params : Popsical.serialize(params),
    });

    return Popsical.request(resources, params, opts);
  }

  static get(resources, params, opts = {}) {
    opts = Object.assign({}, opts, {
      method: 'GET',
      data: null,
    });

    resources = !params ? resources : resources + '?' + qs.stringify(params);

    return Popsical.request(resources, null, opts);
  }

  static put(resources, params, raw = false, opts = {}) {
    opts = Object.assign({}, opts, {
      method: 'PUT',
      data: raw ? params : Popsical.serialize(params),
    });

    return Popsical.request(resources, params, opts);
  }

  static delete(resources, params, raw = false, opts = {}) {
    opts = Object.assign({}, opts, {
      method: 'DELETE',
      data: raw ? params : Popsical.serialize(params),
    });

    return Popsical.request(resources, params, opts);
  }

  static handleResponse(response) {
    const { status, data } = response;

    if (status < 200 && status > 300) {
      throw this.handleError(response);
    }
    return data;
  }

  static handleError(error) {
    const errs = error && error.response ? error.response.data : error;

    return errs;
  }
}
