import React from 'react';
import { BrowserRouter as PopsicalRouter } from 'react-router-dom';
import Router from "./lib/Router";

const App = () => {
  return (
    <PopsicalRouter>
      <Router />
    </PopsicalRouter>
  )
}

export default App;
