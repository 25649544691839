import React from "react";
import { Grid } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import "../../assets/styles/footer.scss";

const Footer = () => {
  return (
    <div className={`footer ${isMobile && "mobile"}`}>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={isMobile ? 6 : 3}>
              <div className={`content ${isMobile && "mobile"}`}>
                <div className="title">
                  <span>COMPANY</span>
                </div>
                <div className="item">
                  <a href="https://popsical.com/pages/aboutus" target="_blank">About Us</a>
                  <a href="https://popsical.com/pages/aboutus" target="_blank">Contact</a>
                  <a href="https://cdn.shopify.com/s/files/1/1659/4335/files/Popsical_Press_Kit.pdf?v=1632381884" target="_blank">Press Kit</a>
                  <a href="https://popsical.com/pages/terms-of-use" target="_blank">Terms of Use</a>
                  <div className={`policy ${isMobile && "mobile"}`}>
                    <a href="https://popsical.com/pages/privacy-policy" target="_blank" className="special">
                      Private Policy
                    </a>
                    <span>All rights reserved &#169; {new Date().getUTCFullYear()}</span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <div className={`content ${isMobile && "mobile"}`}>
                <div className="title">
                  <span>USEFUL LINKS</span>
                </div>
                <div className="item">
                  <a href="https://popsical.com/pages/aboutus" target="_blank">Our Store</a>
                  <a href="http://onelink.to/kzwysn" target="_blank">Mobile App</a>
                  <a href="https://support.popsical.com/" target="_blank">Help</a>
                </div>
              </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <div className={`soc-meds ${isMobile && "mobile"}`}>
                <Grid container spacing={3}>
                  <Grid item>
                    <a href="https://www.facebook.com/PopsicalKaraoke/" target="_blank">
                      <img
                        alt="facebook"
                        src={
                          require(`../../assets/images/icons-social-media-facebook.png`)
                            .default
                        }
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="https://www.instagram.com/popsicalkaraoke/" target="_blank">
                      <img
                        alt="instagram"
                        src={
                          require(`../../assets/images/icons-social-media-instagram.png`)
                            .default
                        }
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="https://www.youtube.com/channel/UCX1NxPhR3dMwmfx8vO4JDew" target="_blank">
                      <img
                        alt="youtube"
                        src={
                          require(`../../assets/images/icons-social-media-youtube.png`)
                            .default
                        }
                      />
                    </a>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={`mobile-policy ${isMobile && "mobile"}`}
            >
              <a href="/#" className="special">
                Private Policy
              </a>
              <span>All rights reserved &#169; {new Date().getUTCFullYear()}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
