import React from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import Home from '../components/home/Home';
import Shot from '../components/shot/Shot';

const Router = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/shots/:shotId" component={Shot} />
    </Switch>
  );
}

export default Router;
