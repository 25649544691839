import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { isMobile } from 'react-device-detect';
import "../../assets/styles/navbar.scss";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (state) => () => {
    setOpen(state);
  }

  const navigationList = () => {
    return (
      <div
        role="presentation"
        className="list"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button key="Product">
            <ListItemText primary="Product" />
          </ListItem>
          <ListItem button key="Premium">
            <ListItemText primary="Premium" />
          </ListItem>
          <ListItem button key="Login/Sign Up">
            <ListItemText primary="Login/Sign Up" />
          </ListItem>
        </List>
      </div>
    )
  }

  return (
    <React.Fragment>
      <AppBar position="static" className="navbar">
        <Toolbar>
          {isMobile && (
            <IconButton onClick={toggleDrawer(true)} edge="start" style={{marginRight: 2}} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          )}
          <div className={`logo ${isMobile && "mobile"}`}>
            <a href="https://popsical.com" target="_blank">
              <img alt="popsical-logo" className="logo-image" src={require(`../../assets/images/Logo-White.png`).default} />
            </a>
          </div>
          {!isMobile && (
            <div className="text-navigations">
              <a href="https://popsical.com/products/popsical-remix2" target="_blank">
                <Button color="inherit">Product</Button>
              </a>
              <a href="https://popsical.com/pages/premium" target="_blank">
                <Button color="inherit">Premium</Button>
              </a>
              <a href="https://app.popsical.com" target="_blank">
                <Button color="inherit">Login/Sign Up</Button>
              </a>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer className="drawer" anchor="left" open={open} onClose={toggleDrawer(false)}>
          {navigationList()}
        </Drawer>
      )}
    </React.Fragment>
  )
}

export default Navbar;
