import React from 'react';
import '../../assets/styles/loader.scss';

const Loader = () => {
  return (
    <div className="lds-facebook">
      <div></div><div></div><div></div>
    </div>
  )
}

export default Loader;
